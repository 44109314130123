import React, { useEffect, useState, useRef, useContext } from 'react';
import { useParams, useSearchParams } from "react-router-dom";
import { SignalRContext } from "../../App";
import WhatsAppIcon from './WhatsAppIcon.svg';
import { Alert, Row, Col, Card, CardText, CardTitle, Button } from 'reactstrap';


function Session({ onPeerLeftSession }) {
  const [loading, setLoading] = useState(true);
  const [makingOffer, setMakingOffer] = useState(false);
  const [connectionLost, setConnectionLost] = useState(false);
  const [searchParams] = useSearchParams();
  let { id } = useParams();

  const selfView = useRef(null);
  const remoteView = useRef(null);
  const stream = useRef(null);
  const fullscreenElementRef = useRef(null);
  const [signalR, signalRError] = useContext(SignalRContext);

  useEffect(() => {
    try {
      if (shareToMicrosoftTeams) { // eslint-disable-line
        shareToMicrosoftTeams.renderButtons(); // eslint-disable-line
      }
    } catch (ex) {
      console.log("teams not ready yet");
    }
  });

  useEffect(() => {
    let shouldActivelyCreateOffers = false;
    const polite = true;
    let ignoreOffer = false;
    let pc;

    const tryToSendOffer = async () => {
      if (shouldActivelyCreateOffers) {
        try {
          await setMakingOffer(true);
          const offer = await pc.createOffer({ iceRestart: true });
          await pc.setLocalDescription(offer);
          signalR.invoke("SendMessage", id, "Desktop", "description", JSON.stringify(pc.localDescription));
        } catch (err) {
          console.error(err);
        } finally {
          await setMakingOffer(false);
        }
      }
    }

    const signalRHandler = async (user, type, message) => {
      try {
        if (type === "description") {
          message = JSON.parse(message);
          const offerCollision =
            message.type === "offer" &&
            (makingOffer || pc.signalingState !== "stable");

          ignoreOffer = !polite && offerCollision;
          if (ignoreOffer) {
            return;
          }
          await pc.setRemoteDescription(message);
          if (message.type === "offer") {
            console.log("Offer received: \n" + message.sdp);
            await pc.setLocalDescription();
            signalR.invoke("SendMessage", id, "Desktop", "description", JSON.stringify(pc.localDescription));
          } else if (message.type === "answer") {
            console.log("Answer received: \n" + message.sdp);
          }
        } else if (type === "negotiationneeded") {
          console.log("received negotiationneeded");
          message = JSON.parse(message);
          if (message.clientType === "webbrowser") {
            shouldActivelyCreateOffers = true;
            await tryToSendOffer();
          }
        } else if (type === "icecandidate") {
          message = JSON.parse(message);
          try {
            await pc.addIceCandidate(message);
          } catch (err) {
            if (!ignoreOffer) {
              throw err;
            }
          }
        } else if (type === "UserJoined") {
          console.log("received UserJoined");
        } else if (type === "UserLeft") {
          console.log("received UserLeft");
          if (onPeerLeftSession instanceof Function) {
            onPeerLeftSession();
          }
        } else {
          console.log("Unknown message:", type, message);
        }
      } catch (err) {
        console.error(err);
      }
    };

    if (signalR) {
      const connect = async () => {
        if (signalR && signalR._connectionState === "Connected") {
          const iceServers = await signalR.invoke("GetIceServers");
          
          const useHighQualityVideo = searchParams.get("lq") === "true" ? false : true;
          const constraints = useHighQualityVideo ? { audio: true, video: true } : {
            audio: true, video: {
              width: 320,
              height: 240,
              frameRate: {
                ideal: 5,
                max: 10
              }
            }
          };
          pc = new RTCPeerConnection({ iceServers: iceServers });

          pc.onicecandidate = ({ candidate }) => signalR.invoke("SendMessage", id, "Desktop", "icecandidate", JSON.stringify(candidate));
          // Let the "negotiationneeded" event trigger offer generation.
          pc.onnegotiationneeded = async () => {
            console.log("negotiation needed");
            signalR.invoke("SendMessage", id, "Desktop", "negotiationneeded", JSON.stringify({ clientType: "webbrowser" }));
            await tryToSendOffer();
          };

          pc.oniceconnectionstatechange = () => {
            console.log("ice connection state changed: " + pc.iceConnectionState);
            if (pc.iceConnectionState === "failed") {
              pc.restartIce();
            }
          };

          pc.onconnectionstatechange = async () => {
            console.log("connection state changed: " + pc.connectionState);
            pc.connectionState === "disconnected" && await setConnectionLost(true);
            pc.connectionState === "connected" && await setConnectionLost(false);
          };

          // Once remote track media arrives, show it in remote video element.
          pc.ontrack = ({ track, streams }) => {
            track.onunmute = async () => {
              console.log("OnTrack was fired!");
              // Don't set srcObject again if it is already set.
              if (!remoteView.current || remoteView.current.srcObject) return;
              if (streams && streams.length > 0) remoteView.current.srcObject = streams[0];
              try {
                await remoteView.current.play();
              } catch (ex) {
                console.log("Failed to play", ex);
              }
            };
          };

          signalR.on("Send", signalRHandler);
          await setLoading(false);

          try {
            const newStream =
              await navigator.mediaDevices.getUserMedia(constraints);
            newStream.getTracks().forEach((track) =>
              pc.addTrack(track, newStream));

            stream.current = newStream;
            selfView.current.srcObject = newStream;
          } catch (err) {
            signalR.invoke("SendMessage", id, "Desktop", "negotiationneeded", JSON.stringify({ clientType: "webbrowser" }));
            console.log("Failed to get local video stream", err);
            if (stream.current) {
              stream.current.getTracks().forEach((track) => {
                track.stop();
              });
            }
          }
          await signalR.invoke("JoinRoom", id);
        }
      }

      connect();


      return () => {
        if (selfView.current) selfView.current.srcObject = null;
        if (remoteView.current) remoteView.current.srcObject = null;
        if (stream.current) {
          stream.current.getTracks().forEach((track) => {
            track.stop();
          });
          stream.current = null;
        }
        if(pc) pc.close();
        // check if signalR is connected
        if (signalR && signalR._connectionState === "Connected") {
          signalR.invoke("LeaveRoom", id); //we don't care, that it is async here
        }

        signalR.off("Send", signalRHandler);
      };
    }
  }, [signalR, id]);

  const toggleFullscreen = () => {
    if (fullscreenElementRef.current) {
      let elem = fullscreenElementRef.current;
      if (!document.fullscreenElement) {
        elem.requestFullscreen().catch((err) => {
          alert(
            `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`,
          );
        });
      } else {
        document.exitFullscreen();
      }
    }
  };

  const remoteViewClick = (e) => {
    const boundingRect = e.target.getBoundingClientRect();
    const payload = {
      x: (e.clientX - boundingRect.left) / boundingRect.width,
      y: (e.clientY - boundingRect.top) / boundingRect.height,
      button: e.button,
      buttons: e.buttons
    };
    signalR.invoke("SendMessage", id, "Desktop", "mouseinteraction", JSON.stringify(payload));
  };

  let contents = loading
    ? <p><em>Loading...</em></p>
    : <>
    {
      connectionLost && <Alert color="danger">
      <p>The connection to the other peer is lost.</p>
      <p>Trying to reestablish the connection...</p>
      </Alert>
    }

    <Row>
        <Col sm="9">
          <Card body>
            <CardTitle tag="h5">
              Remote
            </CardTitle>
          <CardText>
              <video ref={remoteView} autoPlay={true} poster="./placeholderRemote.jpg" onClick={remoteViewClick} style={{ width: "100%", maxHeight: "85vh"}} />
          </CardText>
        </Card>
      </Col>
      <Col sm="3">
        <Card body>
          <CardTitle tag="h5">
            Local Video
          </CardTitle>
          <CardText>
              <video muted={true} poster="./placeholder.jpg" autoPlay={true} style={{ width: "100%"}} ref={selfView} />
              <div style={{ marginTop: 20 }} >
                Share this session:<br />
                <div
                  style={{ display: "inline", marginRight: "16px" }}
                  className="teams-share-button"
                  data-msg-text="Please scan the following QR code using the Remote Assist Hololens App:"
                  data-href={`${window.location.protocol}//${window.location.host}/session/${id}`}>
                </div>
                <a href={"https://wa.me/?text=" + encodeURIComponent(`${window.location.protocol}//${window.location.host}/session/${id}`)} target="_blank" rel="noopener noreferrer"><img width="24" src={WhatsAppIcon} alt="WhatsApp Icon" /></a>
              </div>
              <Button style={{marginTop: "10px"}} onClick={toggleFullscreen}>Toggle fullscreen</Button>
          </CardText>
        </Card>
      </Col>
      </Row >
      <Row>
        <Col sm="12">
          <Alert style={{ marginTop: "10px" }} color="primary">
            <p>Click on the remote video to send mouse clicks to the remote device.</p>
            <p>Use the following buttons to interact with the remote device:</p>
            <ul>
              <li>Place a hologram marker: <kbd>Left mouse button</kbd></li>
            </ul>
          </Alert>
        </Col>
      </Row>
    </>

  if (signalRError) {
    contents = <p>{signalRError}</p>
  }

  return (
    <div ref={fullscreenElementRef}>{contents}</div>
  );
}

export { Session };