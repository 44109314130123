import React from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { useSearchParams } from "react-router-dom";

export function Layout({ children }) {
  const [searchParams] = useSearchParams();
  const isEmbedded = searchParams.get("embedded") === "true" ? true : false;
  
  return (
    <div>
      {!isEmbedded && <NavMenu />}
      <Container>
        {children}
      </Container>
    </div>
  );
}
