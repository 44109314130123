import { useParams, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { Button, CardText, Card, CardTitle, Row, Col } from 'reactstrap';
import { Session } from "./Session";

const SessionInteractionWrapper = ({ children }) => {
  let [showUserInteractionPanel, setShowUserInteractionPanel] = useState(navigator.userActivation.hasBeenActive);
  let [cardMessage, setCardMessage] = useState("Please click the button below to join the session.");
  let [cardTitle, setCardTitle] = useState("Join session");
  let [buttonCaption, setButtonCaption] = useState("Click here to join the session");
  
  let { id } = useParams();
  let content;

  const peerLeftSession = () => {
    setCardMessage("The other user left the session");
    setCardTitle("Session ended");
    setButtonCaption("Click here to rejoin the session");
    setShowUserInteractionPanel(false);
  };

  if (showUserInteractionPanel) {
    content = <Session onPeerLeftSession={peerLeftSession} />
  } else {
    content = (
      <Row>
        <Col sm="6">
          <Card body>
            <CardTitle tag="h5">
              {cardTitle}
            </CardTitle>
            <CardText>
              {cardMessage}
            </CardText>
            <Button color="primary" onClick={() => { setShowUserInteractionPanel(true); }}>{buttonCaption}</Button>
          </Card>
        </Col>
      </Row >
      );
  }

  return (
    <div>
      <h1 id="header">Session {id}</h1>
      <p>Communicate this session ID to the person requiring assistance.</p>
      <div style={{ position: "relative" }}> {content}</div>
    </div>
  );
};

export { SessionInteractionWrapper };