import { useState, useEffect } from "react";

import {
  HubConnectionBuilder,
  LogLevel
} from '@microsoft/signalr';

function useSignalR(hubURL) {
  const [connectionRef, setConnection] = useState();
  const [error, setError] = useState(null);
  function createHubConnection() {
    try {
      const con = new HubConnectionBuilder()
        .withUrl(hubURL, {
          //accessTokenFactory: () => AuthService.getToken(),
        })
        .configureLogging(LogLevel.Information)
        .withAutomaticReconnect()
        .build();
      setConnection(con);
    } catch (err) {
      console.log(err);
      setError(err);
    }
  }

  useEffect(() => {
    createHubConnection();
  }, []);

  useEffect(() => {
    if (connectionRef) {
      connectionRef.start()
    }
    return () => {
      if (connectionRef) {
        connectionRef.stop();
      }
    }
  }, [connectionRef]);


  return [connectionRef, error];
};

export { useSignalR };