import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import connectImg from "./connect.png";
import newSessionImg from "./newSession.png";
import homeStyles from "./homeStyles.module.css";

export function Home() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isEmbedded = searchParams.get("embedded") === "true" ? true : false;
  const [sessionId, setSessionId] = useState('');
  const [hqVideo, setHqVideo] = useState('0');

  let openSession = () => {
    let searchParam = hqVideo ? "?" : "?lq=true&";
    searchParam += isEmbedded ? "embedded=true&" : "";
    return navigate(`/session/${sessionId}${searchParam}`);
  }

  let newSession = () => {

    let searchParam = hqVideo ? "?" : "?lq=true&";
    searchParam += isEmbedded ? "embedded=true&" : "";
    return navigate(`/session/new${searchParam}`);
  }

  return (

    <div className={homeStyles.connectContainer}>
      <input className={homeStyles.newSessionButton} type="image" src={newSessionImg} onClick={() => newSession()}></input>
      <input className={homeStyles.inputField} type="text" inputmode="numeric" onChange={e => setSessionId(e.target.value)} maxLength="5" id="sessionId" value={sessionId}></input>

      <div className={homeStyles.hqVideo}><label><input type="checkbox" onChange={() => setHqVideo(!hqVideo)} checked={hqVideo}></input>Send HQ Video</label></div>
      <input className={homeStyles.connectButton} type="image" src={connectImg} onClick={() => openSession()}></input>
    </div>
  );
}
