import { Home } from "./components/home/Home";
import { GenerateId } from "./components/session/GenerateId";
import { SessionInteractionWrapper } from "./components/session/SessionInteractionWrapper";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/session/new',
    element: <GenerateId />
  },
  {
    path: '/session/:id',
    element: <SessionInteractionWrapper />
  }
];

export default AppRoutes;
